import { grey, pink, teal } from '@material-ui/core/colors';
import { createTheme as createMuiTheme } from '@material-ui/core/styles';

export default function createTheme() {
  return createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: grey[500],
        contrastText: teal[900],
      },
      secondary: {
        main: teal[700],
      },
      error: {
        main: pink.A400,
      },
      text: {
        primary: teal[50],
      },
      background: {
        default: grey[700],
        paper: grey[800],
      },
    },
  });
}
