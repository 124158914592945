import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { Chip, Grid, Typography, makeStyles } from '@material-ui/core';
import history from '@senseisrl/react-template/history';

import RecipeCard from '../RecipeCard';
import Spinner from '../Spinner';
import { loadAction } from './actions';
import messages from './messages';
import { makeSelectRecipe } from './selectors';

const useStyles = makeStyles(theme => ({
  bar: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

function grid(tags, recipes, classes) {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} className={classes.bar}>
        {tags.map(i => (
          <Chip
            key={i.name}
            size="small"
            color="primary"
            label={`${i.name} (${i.count})`}
            onClick={() => history.push(`/recipe?tag=${i.name}`)}
          />
        ))}
      </Grid>
      {recipes.map(i => (
        <Grid key={i.title} item xs={12} sm={4}>
          <RecipeCard title={i.title} to={`/recipe/${i.title}`} />
        </Grid>
      ))}
    </Grid>
  );
}

function tag(s) {
  if (s) {
    const pos = s.indexOf('tag=');
    if (pos) return s.substr(pos + 4);
  }
  return null;
}

function RecipePage(props) {
  useEffect(() => {
    props.onLoad(tag(props.location.search));
  }, [props.location.search]);

  const classes = useStyles();

  const content =
    props.recipe.recipes == null ? (
      <Spinner />
    ) : (
      grid(props.recipe.tags, props.recipe.recipes, classes)
    );
  return (
    <div>
      <Typography variant="h4" color="primary">
        <FormattedMessage {...messages.title} />
      </Typography>
      {content}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  recipe: makeSelectRecipe(),
});

function mapDispatchToProps(dispatch) {
  return {
    onLoad: tag => dispatch(loadAction(tag)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(RecipePage);
