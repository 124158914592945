import { defineMessages } from 'react-intl';

export const scope = 'HousePage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
  },
  description: {
    id: `${scope}.description`,
  },
});
