import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';
import { green, grey, red } from '@material-ui/core/colors';

import Currency from '../Currency';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'stretch',
  },
  title: {
    textTransform: 'capitalize',
  },
  first: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  second: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  credit: {
    backgroundColor: red[800],
    color: grey[100],
    paddingLeft: 30,
    paddingTop: 10,
    paddingBottom: 10,
  },
  debit: {
    backgroundColor: green[700],
    color: grey[100],
    paddingLeft: 30,
    paddingTop: 10,
    paddingBottom: 10,
  },
  arrow12: {
    width: '40px',
    height: 0,
    backgroundColor: green[700],
    borderLeftStyle: 'solid',
    borderLeftWidth: '24px',
    borderLeftColor: red[800],
    borderTopStyle: 'solid',
    borderTopWidth: '36px',
    borderTopColor: 'transparent',
    borderBottomWidth: '36px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
    borderRightWidth: 0,
  },
  arrow21: {
    width: '40px',
    height: 0,
    backgroundColor: green[700],
    borderRightStyle: 'solid',
    borderRightWidth: '24px',
    borderRightColor: red[800],
    borderTopStyle: 'solid',
    borderTopWidth: '36px',
    borderTopColor: 'transparent',
    borderBottomWidth: '36px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
    borderLeftWidth: 0,
  },
});

function Balance(props) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box flexGrow={1}>
        <div
          className={`${classes.first} ${
            props.amount < 0 ? classes.credit : classes.debit
          }`}
        >
          <Typography variant="h5" className={classes.title}>
            {props.first}
          </Typography>
          <Currency value={Math.abs(props.amount)} />
        </div>
      </Box>
      <Box
        className={
          props.amount < 0
            ? classes.arrow12
            : props.amount > 0
            ? classes.arrow21
            : classes.debit
        }
      />
      <Box flexGrow={1}>
        <div
          className={`${classes.second} ${
            props.amount > 0 ? classes.credit : classes.debit
          }`}
        >
          <Typography variant="h5" className={classes.title}>
            {props.second}
          </Typography>
          <Currency value={Math.abs(props.amount)} />
        </div>
      </Box>
    </Box>
  );
}

Balance.propTypes = {
  first: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  second: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  amount: PropTypes.number.isRequired,
};

export default Balance;
