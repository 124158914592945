import {
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  PERMISSIONS_SUCCESS,
} from './constants';

export function loginAction(username, password) {
  return {
    type: LOGIN,
    username,
    password,
  };
}

export function loginSuccessAction(user) {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
}

export function loginFailureAction() {
  return {
    type: LOGIN_FAILURE,
  };
}

export function permissionsSuccessAction(permissions) {
  return {
    type: PERMISSIONS_SUCCESS,
    permissions,
  };
}
