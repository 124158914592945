import { blueGrey, lightBlue, pink, red } from '@material-ui/core/colors';
import { createTheme as createMuiTheme } from '@material-ui/core/styles';

// see color tool https://material-ui.com/customization/color/
// and https://in-your-saas.github.io/material-ui-theme-editor/
export default function createTheme() {
  return createMuiTheme({
    palette: {
      primary: {
        main: lightBlue[900],
        contrastText: lightBlue.A100,
      },
      secondary: {
        main: pink[400],
      },
      error: {
        main: red.A400,
      },
      text: {
        primary: blueGrey[700],
      },
      background: {
        default: lightBlue[100],
        paper: lightBlue[50],
      },
    },
  });
}
