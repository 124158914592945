import { DELETE, LOAD, LOAD_ERROR, LOAD_SUCCESS } from './constants';

export function loadAction(id) {
  return {
    type: LOAD,
    id,
  };
}

export function loadSuccessAction(trip) {
  return {
    type: LOAD_SUCCESS,
    trip,
  };
}

export function loadErrorAction() {
  return {
    type: LOAD_ERROR,
  };
}

export function deleteAction(id) {
  return {
    type: DELETE,
    id,
  };
}
