import {
  ADD_ITEM,
  ADD_ITEM_SUCCESS,
  CHECK_ITEM,
  CHECK_ITEM_SUCCESS,
  DELETE,
  DELETE_ITEMS,
  LOAD,
  LOAD_ERROR,
  LOAD_SUCCESS,
} from './constants';

export function loadAction(id) {
  return {
    type: LOAD,
    id,
  };
}

export function loadSuccessAction(checklist) {
  return {
    type: LOAD_SUCCESS,
    checklist,
  };
}

export function loadErrorAction() {
  return {
    type: LOAD_ERROR,
  };
}

export function deleteAction(id) {
  return {
    type: DELETE,
    id,
  };
}

export function addItemAction(id, text) {
  return {
    type: ADD_ITEM,
    id,
    text,
  };
}

export function addItemSuccessAction(id, itemId, text) {
  return {
    type: ADD_ITEM_SUCCESS,
    itemId,
    text,
  };
}

export function deleteItemsAction(id, condition) {
  return {
    type: DELETE_ITEMS,
    id,
    condition,
  };
}

export function checkItemAction(id, itemId, done) {
  return {
    type: CHECK_ITEM,
    id,
    itemId,
    done: !!done,
  };
}

export function checkItemSuccessAction(id, itemId, done) {
  return {
    type: CHECK_ITEM_SUCCESS,
    id,
    itemId,
    done: !!done,
  };
}
