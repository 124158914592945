import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectToastDomain = state => state.toast || initialState;

const makeSelectToast = () =>
  createSelector(selectToastDomain, substate => substate);

export { selectToastDomain, makeSelectToast };
