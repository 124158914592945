import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectGlobalDomain = state => state.global || initialState;

const makeSelectGlobal = () =>
  createSelector(selectGlobalDomain, substate => substate);

export { selectGlobalDomain, makeSelectGlobal };
