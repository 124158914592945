import { call, put, takeLatest } from 'redux-saga/effects';

import { apiGet, apiToken } from '@senseisrl/react-template/utils/api';

import { toastErrorAction } from '../Toast/actions';
import {
  loginFailureAction,
  loginSuccessAction,
  permissionsSuccessAction,
} from './actions';
import { LOGIN, LOGIN_SUCCESS } from './constants';

export function* login(action) {
  try {
    const response = yield call(apiToken, '/oauth/token', {
      grant_type: 'password',
      username: action.username,
      password: action.password,
    });
    const pos0 = response.data.access_token.indexOf('.');
    const pos1 = response.data.access_token.lastIndexOf('.');
    const user = JSON.parse(
      Buffer.from(
        response.data.access_token.substring(pos0 + 1, pos1),
        'base64',
      ),
    );
    yield put(loginSuccessAction(user));
  } catch (err) {
    yield put(loginFailureAction());
    yield put(toastErrorAction('LoginPage.bad'));
  }
}

export function* loginSuccess(action) {
  if (action.user)
    try {
      const response = yield call(apiGet, '/api/v1/permissions');
      yield put(permissionsSuccessAction(response.data));
    } catch (err) {
      //yield put(toastErrorAction('LoginPage.bad'));
    }
}

export default function* saga() {
  yield takeLatest(LOGIN, login);
  yield takeLatest(LOGIN_SUCCESS, loginSuccess);
}
