import produce from 'immer';

import { LOAD, LOAD_ERROR, LOAD_SUCCESS } from './constants';

export const initialState = {
  name: null,
  creator: null,
  start: null,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD:
        draft.name = null;
        draft.creator = null;
        draft.start = null;
        break;
      case LOAD_SUCCESS:
        draft.name = action.trip.name;
        draft.creator = action.trip.creator;
        draft.start = action.trip.start;
        break;
      case LOAD_ERROR:
        draft.name = null;
        draft.creator = null;
        draft.start = null;
        break;
    }
  });

export default reducer;
