import { call, put, takeLatest } from 'redux-saga/effects';

import { apiGet } from '@senseisrl/react-template/utils/api';

import { toastErrorAction } from '../Toast/actions';
import { loadErrorAction, loadSuccessAction } from './actions';
import { LOAD } from './constants';

export function* load(action) {
  try {
    const response = yield call(apiGet, `/api/v1/recipes/${action.id}`);
    yield put(loadSuccessAction(response.data));
  } catch (err) {
    yield put(loadErrorAction());
    yield put(toastErrorAction('RecipeDetailPage.bad'));
  }
}

export default function* saga() {
  yield takeLatest(LOAD, load);
}
