import PropTypes from 'prop-types';
import React, { useLayoutEffect, useRef } from 'react';

import { blue } from '@material-ui/core/colors';

import { drawRoute } from './router';

// getting started: https://developer.here.com/documentation/maps/latest/dev_guide/

function Map(props) {
  const mapRef = useRef(null);
  const height = props.height || 500;

  /**
   * Create the map instance
   * While `useEffect` could also be used here, `useLayoutEffect` will render
   * the map sooner
   */
  useLayoutEffect(() => {
    // `mapRef.current` will be `undefined` when this hook first runs; edge case that
    if (!mapRef.current) return;

    const lat = props.lat || 41.9027835;
    const long = props.long || 12.4963655;
    const zoom = props.zoom || 10;

    const H = window.H;
    const platform = new H.service.Platform({
      apikey: 'uFJMkQw4zSs8xeMd_dBVx-zeE72hB5WHjQKMHKbNjcQ',
    });
    const defaultLayers = platform.createDefaultLayers({ lg: 'it' });
    const map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: lat, lng: long },
      zoom: zoom,
      pixelRatio: window.devicePixelRatio || 1,
    });

    // add a resize listener to make sure that the map occupies the whole container
    window.addEventListener('resize', () => map.getViewPort().resize());

    // make the map interactive
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

    const ui = H.ui.UI.createDefault(map, defaultLayers);

    if (props.route != null && props.route.length >= 2) {
      const routeColor = props.routeColor || blue[500];
      const markerColor = props.markerColor || blue[900];
      const router = platform.getRoutingService(null, 8);
      let markerIcon = null;
      let a = null;
      for (let b of props.route) {
        if (a != null) {
          if (markerIcon == null) {
            markerIcon = new H.map.Icon(
              '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="' +
                markerColor +
                '" d="M12 0c-4.198 0-8 3.403-8 7.602 0 6.243 6.377 6.903 8 16.398 1.623-9.495 8-10.155 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.342-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>',
            );
            drawRoute(H, map, router, a, b, routeColor, markerIcon, false);
          } else {
            drawRoute(H, map, router, a, b, routeColor, markerIcon, true);
          }
        }
        a = b;
      }
    }
    // This will act as a cleanup to run once this hook runs again.
    // This includes when the component un-mounts
    return () => {
      map.dispose();
    };
  }, [mapRef]); // This will run this hook every time this ref is updated

  return <div className="map" ref={mapRef} style={{ height: height }} />;
}

Map.propTypes = {
  height: PropTypes.number,
  zoom: PropTypes.number,
  lat: PropTypes.number,
  long: PropTypes.number,
  route: PropTypes.arrayOf(PropTypes.string),
  routeColor: PropTypes.string,
  markerColor: PropTypes.string,
};

export default Map;
