import { TOAST_IN, TOAST_OUT } from './constants';

export function toastErrorAction(messageId) {
  return {
    type: TOAST_IN,
    messageId,
    severity: 'error',
  };
}

export function toastWarningAction(messageId) {
  return {
    type: TOAST_IN,
    messageId,
    severity: 'warning',
  };
}

export function toastInfoAction(messageId) {
  return {
    type: TOAST_IN,
    messageId,
    severity: 'info',
  };
}

export function toastSuccessAction(messageId) {
  return {
    type: TOAST_IN,
    messageId,
    severity: 'success',
  };
}

export function toastOutAction() {
  return {
    type: TOAST_OUT,
  };
}
