import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { Container } from '@material-ui/core';

import Header from '../../components/Header';
import ChecklistDetailPage from '../ChecklistDetailPage';
import ChecklistPage from '../ChecklistPage';
import ErrorPage from '../ErrorPage';
import HomePage from '../HomePage';
import HousePage from '../HousePage';
import LoginPage from '../LoginPage';
import RecipeDetailPage from '../RecipeDetailPage';
import RecipePage from '../RecipePage';
import Spinner from '../Spinner';
import Toast from '../Toast';
import TripDetailPage from '../TripDetailPage';
import TripPage from '../TripPage';
import { loginAction } from './actions';
import { makeSelectGlobal } from './selectors';

function App(props) {
  if (!props.global.currentUser)
    return (
      <div>
        <Header />
        <LoginPage onLogin={props.onLogin} />
        <Toast />
      </div>
    );

  if (props.global.permissions == null)
    return (
      <div>
        <Header user={props.global.currentUser} />
        <Spinner />
      </div>
    );

  return (
    <div>
      <Header
        user={props.global.currentUser}
        permissions={props.global.permissions}
      />
      <Container maxWidth="lg">
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/checklist" component={ChecklistPage} />
          <Route exact path="/checklist/:id" component={ChecklistDetailPage} />
          <Route exact path="/recipe" component={RecipePage} />
          <Route exact path="/recipe/:id" component={RecipeDetailPage} />
          <Route exact path="/trip" component={TripPage} />
          <Route exact path="/trip/:id" component={TripDetailPage} />
          <Route exact path="/house" component={HousePage} />
          <Route component={ErrorPage} />
        </Switch>
      </Container>
      <Toast />
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  global: makeSelectGlobal(),
});

function mapDispatchToProps(dispatch) {
  return {
    onLogin: (username, password) => dispatch(loginAction(username, password)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(App);
