import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import Balance from '../Balance';
import Currency from '../Currency';
import Spinner from '../Spinner';
import { loadAction } from './actions';
import messages from './messages';
import { makeSelectHouse } from './selectors';

const useStyles = makeStyles({
  root: {
    marginTop: '30px',
  },
  cardTitle: {
    marginBottom: '20px',
  },
  card: {
    height: '100px',
  },
});

function HousePage(props) {
  useEffect(() => {
    props.onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  let balance = 0;
  const tiles = [];
  if (props.house.logs != null)
    for (let i of props.house.logs) {
      if (i.martina) balance += i.martina;
      if (i.luca) balance -= i.luca;
      tiles.push(
        <Card className={classes.card}>
          <CardContent>
            <Typography
              variant="h5"
              color="inherit"
              className={classes.cardTitle}
            >
              {i.category}
            </Typography>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Currency value={i.martina} round />
              </Grid>
              <Grid item>
                <Currency value={i.luca} round />
              </Grid>
            </Grid>
          </CardContent>
        </Card>,
      );
    }
  return (
    <div>
      <Typography variant="h4" color="inherit">
        <FormattedMessage {...messages.title} />
      </Typography>
      {props.house.logs == null ? (
        <Spinner />
      ) : (
        <Balance first="Martina" second="Luca" amount={balance / 2} />
      )}
      <Grid container spacing={3} className={classes.root}>
        {tiles.map(i => (
          <Grid item xs={12} sm={4}>
            {i}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  house: makeSelectHouse(),
});

function mapDispatchToProps(dispatch) {
  return {
    onLoad: tag => dispatch(loadAction(tag)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(HousePage);
