export function drawRoute(H, map, router, a, b, color, markerIcon, append) {
  // see https://developer.here.com/documentation/maps/latest/dev_guide/topics/routing.html
  const onSuccess = result => {
    if (result.routes.length > 0) {
      result.routes[0].sections.forEach(section => {
        // Create a linestring to use as a point source for the route line
        const linestring = H.geo.LineString.fromFlexiblePolyline(
          section.polyline,
        );

        // Create a polyline to display the route:
        const routeLine = new H.map.Polyline(linestring, {
          style: { strokeColor: color, lineWidth: 4 },
        });

        const endMarker = new H.map.Marker(section.arrival.place.location, {
          icon: markerIcon,
        });
        if (append) map.addObjects([routeLine, endMarker]);
        else {
          const startMarker = new H.map.Marker(
            section.departure.place.location,
            { icon: markerIcon },
          );
          map.addObjects([routeLine, startMarker, endMarker]);
        }

        // Set the map's viewport to make the whole route visible:
        //map.getViewModel().setLookAtData({bounds: routeLine.getBoundingBox()});
      });
    }
  };
  const onError = error => {
    console.log('cannot calculate route', error);
  };
  router.calculateRoute(
    {
      routingMode: 'fast',
      transportMode: 'car',
      origin: a,
      destination: b,
      return: 'polyline',
    },
    onSuccess,
    onError,
  );
}
