import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import messages from '../MainCard/messages';

const useStyles = makeStyles({
  title: {
    textTransform: 'capitalize',
  },
});

function ChecklistCard(props) {
  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <div>
            <Typography
              gutterBottom
              variant="h5"
              color="primary"
              className={classes.title}
            >
              {props.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {props.description}
            </Typography>
          </div>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          fullWidth
          component={Link}
          to={props.to}
        >
          <FormattedMessage {...messages.nav} />
        </Button>
      </CardActions>
    </Card>
  );
}

ChecklistCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  done: PropTypes.number,
  total: PropTypes.number,
  to: PropTypes.string.isRequired,
};

export default ChecklistCard;
