import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import history from '@senseisrl/react-template/history';
import {
  apiGet,
  apiPost,
  createdId,
} from '@senseisrl/react-template/utils/api';

import { toastErrorAction } from '../Toast/actions';
import { loadErrorAction, loadSuccessAction } from './actions';
import { CREATE, LOAD } from './constants';

export function* load(action) {
  try {
    const response = yield call(apiGet, '/api/v2/checklists', { size: 100 });
    yield put(loadSuccessAction(response.data.content));
  } catch (err) {
    yield put(loadErrorAction());
    yield put(toastErrorAction('ChecklistPage.bad'));
  }
}

export function* create(action) {
  if (action.title)
    try {
      const response = yield call(apiPost, '/api/v2/checklists', {
        title: action.title,
      });
      history.push('/checklist/' + createdId(response.headers));
    } catch (err) {
      yield put(toastErrorAction('ChecklistPage.bad'));
    }
}

export default function* saga() {
  yield takeLatest(LOAD, load);
  yield takeEvery(CREATE, create);
}
