import produce from 'immer';

import { LOAD, LOAD_ERROR, LOAD_SUCCESS } from './constants';

export const initialState = {
  title: null,
  tags: null,
  sections: null,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD:
        draft.title = null;
        break;
      case LOAD_SUCCESS:
        draft.title = action.recipe.title;
        draft.tags = action.recipe.tags;
        draft.sections = action.recipe.sections;
        break;
      case LOAD_ERROR:
        draft.title = null;
        draft.tags = null;
        draft.sections = null;
        break;
    }
  });

export default reducer;
