import { defineMessages } from 'react-intl';

export const scope = 'LoginPage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
  },
  username: {
    id: `${scope}.username`,
  },
  password: {
    id: `${scope}.password`,
  },
  login: {
    id: `${scope}.login`,
  },
  invalid: {
    id: `${scope}.invalid`,
  },
  bad: {
    id: `${scope}.bad`,
  },
});
