import { LOAD, LOAD_ERROR, LOAD_SUCCESS } from './constants';

export function loadAction() {
  return {
    type: LOAD,
  };
}

export function loadSuccessAction(logs) {
  return {
    type: LOAD_SUCCESS,
    logs,
  };
}

export function loadErrorAction() {
  return {
    type: LOAD_ERROR,
  };
}
