import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectChecklistDetailDomain = state =>
  state.checklistDetail || initialState;

const makeSelectChecklistDetail = () =>
  createSelector(selectChecklistDetailDomain, substate => substate);

export { selectChecklistDetailDomain, makeSelectChecklistDetail };
