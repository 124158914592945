import PropTypes from 'prop-types';
import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function ConfirmDialog(props) {
  const classes = useStyles();
  const button3 = props.ok3 ? (
    <Button
      variant="outlined"
      color="primary"
      onClick={() => {
        props.onClose();
        if (props.onOk3) props.onOk3();
      }}
    >
      {props.ok3}
    </Button>
  ) : null;
  const button2 = props.ok2 ? (
    <Button
      variant="outlined"
      color="primary"
      onClick={() => {
        props.onClose();
        if (props.onOk2) props.onOk2();
      }}
    >
      {props.ok2}
    </Button>
  ) : null;
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth={props.maxWidth}
    >
      <DialogTitle>
        {props.title}
        <IconButton className={classes.closeButton} onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={props.dividers}>{props.children}</DialogContent>
      <DialogActions>
        {button3}
        {button2}
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            props.onClose();
            if (props.onOk) props.onOk();
          }}
        >
          {props.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  ok: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  ok2: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ok3: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func,
  onOk2: PropTypes.func,
  onOk3: PropTypes.func,
  maxWidth: PropTypes.string,
  dividers: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default ConfirmDialog;
