import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Button, Typography } from '@material-ui/core';

import messages from './messages';

function ErrorPage() {
  return (
    <div>
      <Typography variant="h3" color="primary">
        <FormattedMessage {...messages.title} />
      </Typography>
      <Button variant="outlined" color="primary" component={Link} to="/">
        <FormattedMessage {...messages.back} />
      </Button>
    </div>
  );
}

export default ErrorPage;
