import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import history from '@senseisrl/react-template/history';
import {
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
} from '@senseisrl/react-template/utils/api';

import { toastErrorAction } from '../Toast/actions';
import {
  addItemSuccessAction,
  checkItemSuccessAction,
  deleteItemSuccessAction,
  loadErrorAction,
  loadSuccessAction,
} from './actions';
import { DELETE, LOAD } from './constants';

export function* load(action) {
  try {
    const response = yield call(apiGet, `/api/v1/trips/${action.id}`);
    yield put(loadSuccessAction(response.data));
  } catch (err) {
    yield put(loadErrorAction());
    yield put(toastErrorAction('TripDetailPage.bad'));
  }
}

export function* del(action) {
  try {
    yield call(apiDelete, `/api/v1/trips/${action.id}`);
    history.push('/trip');
  } catch (err) {
    yield put(toastErrorAction('TripDetailPage.bad'));
  }
}

export default function* saga() {
  yield takeLatest(LOAD, load);
  yield takeEvery(DELETE, del);
}
