import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectTripDetailDomain = state => state.tripDetail || initialState;

const makeSelectTripDetail = () =>
  createSelector(selectTripDetailDomain, substate => substate);

export { selectTripDetailDomain, makeSelectTripDetail };
