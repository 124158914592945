import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectRecipeDetailDomain = state => state.recipeDetail || initialState;

const makeSelectRecipeDetail = () =>
  createSelector(selectRecipeDetailDomain, substate => substate);

export { selectRecipeDetailDomain, makeSelectRecipeDetail };
