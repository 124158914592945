import PropTypes from 'prop-types';
import React, { Children, useState } from 'react';

import { Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

function OptionSelect(props) {
  const [anchor, setAnchor] = useState(null);

  const handleClick = event => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  let current = props.children.find(i => i.props.value === props.value);
  if (props.map) current = props.map(props.value, current.props.children);
  else current = current.props.children;

  return (
    <div>
      <Button
        color="inherit"
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
      >
        {current}
      </Button>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {Children.map(props.children, i => (
          <MenuItem
            key={i.key ? i.key : i.props.value}
            value={i.props.value}
            onClick={() => {
              props.onClick(i.props.value);
              handleClose();
            }}
          >
            {i.props.children}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

OptionSelect.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  map: PropTypes.func,
};

export default OptionSelect;
