import { LOAD, LOAD_ERROR, LOAD_SUCCESS } from './constants';

export function loadAction(id) {
  return {
    type: LOAD,
    id,
  };
}

export function loadSuccessAction(recipe) {
  return {
    type: LOAD_SUCCESS,
    recipe,
  };
}

export function loadErrorAction() {
  return {
    type: LOAD_ERROR,
  };
}
