import produce from 'immer';

import { LOGIN_FAILURE, LOGIN_SUCCESS, PERMISSIONS_SUCCESS } from './constants';

export const initialState = {
  currentUser: null,
  permissions: null,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOGIN_SUCCESS:
        if (action.user) console.log('logged in', action.user);
        else console.log('logged out');
        draft.currentUser = action.user;
        draft.permissions = null;
        break;
      case LOGIN_FAILURE:
        console.log('logged out');
        draft.currentUser = draft.permissions = null;
        break;
      case PERMISSIONS_SUCCESS:
        draft.permissions = action.permissions;
        break;
    }
  });

export default reducer;
