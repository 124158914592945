import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { Typography, makeStyles } from '@material-ui/core';

import Spinner from '../Spinner';
import Wiki from '../Wiki';
import { loadAction } from './actions';
import { makeSelectRecipeDetail } from './selectors';

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'capitalize',
  },
}));

function RecipeDetailPage(props) {
  useEffect(() => {
    props.onLoad(props.match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classes = useStyles();

  if (props.recipe.title == null) return <Spinner />;

  return (
    <div>
      <Typography variant="h4" color="primary" className={classes.title}>
        {props.recipe.title}
      </Typography>
      <Wiki sections={props.recipe.sections} />
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  recipe: makeSelectRecipeDetail(),
});

function mapDispatchToProps(dispatch) {
  return {
    onLoad: id => dispatch(loadAction(id)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(RecipeDetailPage);
