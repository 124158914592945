import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectHouseDomain = state => state.house || initialState;

const makeSelectHouse = () =>
  createSelector(selectHouseDomain, substate => substate);

export { selectHouseDomain, makeSelectHouse };
