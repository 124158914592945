import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import createDarkTheme from './dark';
import createLightTheme from './light';
import { makeSelectTheme } from './selectors';

function ThemeProvider(props) {
  return (
    <MuiThemeProvider
      theme={props.theme.dark ? createDarkTheme() : createLightTheme()}
    >
      <CssBaseline />
      {React.Children.only(props.children)}
    </MuiThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const mapStateToProps = createStructuredSelector({
  theme: makeSelectTheme(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(ThemeProvider);
