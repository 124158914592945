import { combineReducers } from 'redux';

import language from '@senseisrl/react-template/LanguageProvider/reducer';

import global from './components/App/reducer';
import checklistDetail from './components/ChecklistDetailPage/reducer';
import checklist from './components/ChecklistPage/reducer';
import house from './components/HousePage/reducer';
import recipeDetail from './components/RecipeDetailPage/reducer';
import recipe from './components/RecipePage/reducer';
import theme from './components/ThemeProvider/reducer';
import toast from './components/Toast/reducer';
import tripDetail from './components/TripDetailPage/reducer';
import trip from './components/TripPage/reducer';

const rootReducer = combineReducers({
  language,
  theme,
  global,
  toast,
  checklist,
  checklistDetail,
  recipe,
  house,
  recipeDetail,
  trip,
  tripDetail,
});

export default rootReducer;
