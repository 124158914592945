import produce from 'immer';

import { LOAD_ERROR, LOAD_SUCCESS } from './constants';

export const initialState = {
  checklists: null,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_SUCCESS:
        draft.checklists = action.checklists;
        break;
      case LOAD_ERROR:
        draft.checklists = [];
        break;
    }
  });

export default reducer;
