import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import history from '@senseisrl/react-template/history';
import {
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  createdId,
} from '@senseisrl/react-template/utils/api';

import { toastErrorAction } from '../Toast/actions';
import {
  addItemSuccessAction,
  checkItemSuccessAction,
  loadErrorAction,
  loadSuccessAction,
} from './actions';
import { ADD_ITEM, CHECK_ITEM, DELETE, DELETE_ITEMS, LOAD } from './constants';
import { selectChecklistDetailDomain } from './selectors';

export function* load(action) {
  try {
    const response = yield call(apiGet, `/api/v2/checklists/${action.id}`);
    yield put(loadSuccessAction(response.data));
  } catch (err) {
    yield put(loadErrorAction());
    yield put(toastErrorAction('ChecklistDetailPage.bad'));
  }
}

export function* del(action) {
  try {
    yield call(apiDelete, `/api/v2/checklists/${action.id}`);
    history.push('/checklist');
  } catch (err) {
    yield put(toastErrorAction('ChecklistDetailPage.bad'));
  }
}

export function* add(action) {
  try {
    const response = yield call(
      apiPost,
      `/api/v2/checklists/${action.id}/items`,
      {
        text: action.text,
      },
    );
    yield put(
      addItemSuccessAction(action.id, createdId(response.headers), action.text),
    );
  } catch (err) {
    yield put(toastErrorAction('ChecklistDetailPage.bad'));
  }
}

export function* delItems(action) {
  const patch = [];
  if (action.condition === 'last') {
    const state = yield select(selectChecklistDetailDomain);
    if (state.items.length > 0) {
      patch.push({ id: state.items[state.items.length - 1].id });
    }
  } else if (action.condition === 'checked') {
    const state = yield select(selectChecklistDetailDomain);
    for (let i of state.items) {
      if (i.done) {
        patch.push({ id: i.id });
      }
    }
  }
  try {
    if (patch.length > 0) {
      yield call(apiPatch, `/api/v2/checklists/${action.id}/items`, patch);
      const response = yield call(apiGet, `/api/v2/checklists/${action.id}`);
      yield put(loadSuccessAction(response.data));
    }
  } catch (err) {
    yield put(toastErrorAction('ChecklistDetailPage.bad'));
  }
}

export function* checkItem(action) {
  try {
    yield call(
      apiPatch,
      `/api/v2/checklists/${action.id}/items/${action.itemId}`,
      { done: action.done },
    );
    yield put(checkItemSuccessAction(action.id, action.itemId, action.done));
  } catch (err) {
    yield put(toastErrorAction('ChecklistDetailPage.bad'));
  }
}

export default function* saga() {
  yield takeLatest(LOAD, load);
  yield takeEvery(DELETE, del);
  yield takeEvery(ADD_ITEM, add);
  yield takeEvery(DELETE_ITEMS, delItems);
  yield takeEvery(CHECK_ITEM, checkItem);
}
