import produce from 'immer';

import {
  ADD_ITEM_SUCCESS,
  CHECK_ITEM_SUCCESS,
  LOAD,
  LOAD_ERROR,
  LOAD_SUCCESS,
} from './constants';

export const initialState = {
  name: null,
  creator: null,
  items: null,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD:
        draft.name = null;
        draft.creator = null;
        draft.items = null;
        break;
      case LOAD_SUCCESS:
        draft.name = action.checklist.name;
        draft.creator = action.checklist.creator;
        draft.items = action.checklist.items || [];
        break;
      case LOAD_ERROR:
        draft.name = null;
        draft.creator = null;
        draft.items = null;
        break;
      case ADD_ITEM_SUCCESS:
        draft.items = [
          ...state.items,
          {
            id: action.itemId,
            name: action.text,
            done: false,
          },
        ];
        break;
      case CHECK_ITEM_SUCCESS:
        const pos = state.items.findIndex(i => i.id === action.itemId);
        draft.items = [
          ...state.items.slice(0, pos),
          {
            id: state.items[pos].id,
            name: state.items[pos].name,
            done: action.done,
          },
          ...state.items.slice(pos + 1),
        ];
        break;
    }
  });

export default reducer;
