import PropTypes from 'prop-types';
import React from 'react';

function Currency(props) {
  const currency = props.currency ? props.currency : '€';
  const value = props.value
    ? !!props.round
      ? props.value.toLocaleString(undefined, { maximumFractionDigits: 0 })
      : props.value.toLocaleString(undefined, { maximumFractionDigits: 2 })
    : 0;
  return (
    <span>
      {value} {currency}
    </span>
  );
}

Currency.propTypes = {
  value: PropTypes.number.isRequired,
  currency: PropTypes.string,
  round: PropTypes.bool,
};

export default Currency;
