import { CREATE, LOAD, LOAD_ERROR, LOAD_SUCCESS } from './constants';

export function loadAction() {
  return {
    type: LOAD,
  };
}

export function loadSuccessAction(trips) {
  return {
    type: LOAD_SUCCESS,
    trips,
  };
}

export function loadErrorAction() {
  return {
    type: LOAD_ERROR,
  };
}

export function createAction(title) {
  return {
    type: CREATE,
    title,
  };
}
