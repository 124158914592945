import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectThemeDomain = state => state.theme || initialState;

const makeSelectTheme = () =>
  createSelector(
    selectThemeDomain, 
    substate => substate,
  );

export { selectThemeDomain, makeSelectTheme };
