import { LOAD, LOAD_ERROR, LOAD_SUCCESS } from './constants';

export function loadAction(tag) {
  return {
    type: LOAD,
    tag,
  };
}

export function loadSuccessAction(recipes, tags) {
  return {
    type: LOAD_SUCCESS,
    recipes,
    tags,
  };
}

export function loadErrorAction() {
  return {
    type: LOAD_ERROR,
  };
}
