import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import messages from './messages';

const useStyles = makeStyles(theme => ({
  form: {
    margin: '0px',
    width: '100%', // fixes horizontal scrollbar introduced by mui-spacing https://stackoverflow.com/questions/45519275
  },
  title: {
    marginTop: '30px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-10px',
      marginBottom: '-10px',
    },
  },
}));

function LoginPage(props) {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [error, setError] = useState(false);
  const [password, setPassword] = useState('');

  // help with interactive https://material-ui.com/components/grid/
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={2}
      className={classes.form}
    >
      <Grid item>
        <Typography variant="h4" color="primary" className={classes.title}>
          <FormattedMessage {...messages.title} />
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          error={error}
          helperText={error ? <FormattedMessage {...messages.invalid} /> : null}
          label={<FormattedMessage {...messages.username} />}
          variant="outlined"
          value={username}
          autoComplete="username"
          onChange={event => setUsername(event.target.value)}
          autoFocus
        />
      </Grid>
      <Grid item>
        <TextField
          label={<FormattedMessage {...messages.password} />}
          variant="outlined"
          type="password"
          value={password}
          autoComplete="current-password"
          onChange={event => setPassword(event.target.value)}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (username) {
              setError(false);
              props.onLogin(username.trim(), password.trim());
            } else setError(true);
          }}
        >
          <FormattedMessage {...messages.login} />
        </Button>
      </Grid>
    </Grid>
  );
}

LoginPage.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default LoginPage;
