import { defineMessages } from 'react-intl';

export const scope = 'ErrorPage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
  },
  back: {
    id: `${scope}.back`,
  },
});
