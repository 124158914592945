import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectChecklistDomain = state => state.checklist || initialState;

const makeSelectChecklist = () =>
  createSelector(selectChecklistDomain, substate => substate);

export { selectChecklistDomain, makeSelectChecklist };
