import produce from 'immer';

import { TOGGLE_THEME } from './constants';

export const initialState = {
  dark: false,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TOGGLE_THEME:
        draft.dark = !draft.dark;
        break;
    }
  });

export default reducer;
