import { spawn } from 'redux-saga/effects';

import saga1 from './components/App/saga';
import saga3 from './components/ChecklistDetailPage/saga';
import saga2 from './components/ChecklistPage/saga';
import saga6 from './components/HousePage/saga';
import saga5 from './components/RecipeDetailPage/saga';
import saga4 from './components/RecipePage/saga';
import saga8 from './components/TripDetailPage/saga';
import saga7 from './components/TripPage/saga';

export default function* rootSaga() {
  yield spawn(saga1);
  yield spawn(saga2);
  yield spawn(saga3);
  yield spawn(saga4);
  yield spawn(saga5);
  yield spawn(saga6);
  yield spawn(saga7);
  yield spawn(saga8);
}
