import { defineMessages } from 'react-intl';

export const scope = 'TripDetailPage';

export default defineMessages({
  createdBy: {
    id: `${scope}.createdBy`,
  },
  bad: {
    id: `${scope}.bad`,
  },
  addTitle: {
    id: `${scope}.addTitle`,
  },
  add: {
    id: `${scope}.add`,
  },
  name: {
    id: `${scope}.name`,
  },
  deleteTitle: {
    id: `${scope}.deleteTitle`,
  },
  deleteConfirm: {
    id: `${scope}.deleteConfirm`,
  },
  delete: {
    id: `${scope}.delete`,
  },
});
