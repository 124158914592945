import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';

import messages from './messages';

function MainCard(props) {
  return (
    <Card>
      <CardActionArea>
        <CardMedia component="img" height="240" image={props.image} />
        <CardContent>
          <Typography gutterBottom variant="h5" color="primary">
            {props.title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          fullWidth
          component={Link}
          to={props.to}
        >
          <FormattedMessage {...messages.nav} />
        </Button>
      </CardActions>
    </Card>
  );
}

MainCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  image: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export default MainCard;
