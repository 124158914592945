import produce from 'immer';

import { LOAD_ERROR, LOAD_SUCCESS } from './constants';

export const initialState = {
  recipes: null,
  tags: null,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_SUCCESS:
        draft.recipes = action.recipes;
        draft.tags = action.tags;
        break;
      case LOAD_ERROR:
        draft.recipes = [];
        draft.tags = [];
        break;
    }
  });

export default reducer;
