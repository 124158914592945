import PropTypes from 'prop-types';
import React from 'react';

import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'capitalize',
  },
  text: {
    fontSize: '120%',
    '&:first-letter': {
      fontSize: '130%',
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontWeight: 900,
    },
    textAlign: 'justify',
  },
  list: {
    fontSize: '150%',
    paddingLeft: '20px',
  },
}));

function Wiki(props) {
  const classes = useStyles();

  const paragraph = p => {
    if (p.items) {
      return (
        <ul className={classes.list}>
          {p.items.map(i => (
            <li>{i}</li>
          ))}
        </ul>
      );
    }
    return <p className={classes.text}>{p.text}</p>;
  };

  const section = s => {
    const header = s.title ? (
      <Typography variant="h5" className={classes.title}>
        {s.title}
      </Typography>
    ) : null;
    return (
      <section>
        {header}
        {s.paragraphs.map(i => paragraph(i))}
      </section>
    );
  };

  return <div>{props.sections.map(i => section(i))}</div>;
}

Wiki.propTypes = {
  sections: PropTypes.array.isRequired,
};
export default Wiki;
