import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { Grid, Typography } from '@material-ui/core';

import { makeSelectGlobal } from '../App/selectors';
import messages2 from '../ChecklistPage/messages';
import messages3 from '../HousePage/messages';
import MainCard from '../MainCard';
import messages4 from '../RecipePage/messages';
import messages5 from '../TripPage/messages';
import messages from './messages';

export function isAuthorized(permissions, task) {
  return permissions && permissions[task];
}

function HomePage(props) {
  const checklist = isAuthorized(props.global.permissions, 'checklists') ? (
    <Grid item xs={12} md={4}>
      <MainCard
        image="checklist.jpg"
        title={<FormattedMessage {...messages2.title} />}
        description={<FormattedMessage {...messages2.description} />}
        to="/checklist"
      />
    </Grid>
  ) : null;
  const house = isAuthorized(props.global.permissions, 'house') ? (
    <Grid item xs={12} md={4}>
      <MainCard
        image="house.jpg"
        title={<FormattedMessage {...messages3.title} />}
        description={<FormattedMessage {...messages3.description} />}
        to="/house"
      />
    </Grid>
  ) : null;
  const recipes = isAuthorized(props.global.permissions, 'recipes') ? (
    <Grid item xs={12} md={4}>
      <MainCard
        image="food.jpg"
        title={<FormattedMessage {...messages4.title} />}
        description={<FormattedMessage {...messages4.description} />}
        to="/recipe"
      />
    </Grid>
  ) : null;
  const trips = isAuthorized(props.global.permissions, 'trips') ? (
    <Grid item xs={12} md={4}>
      <MainCard
        image="road.jpg"
        title={<FormattedMessage {...messages5.title} />}
        description={<FormattedMessage {...messages5.description} />}
        to="/trip"
      />
    </Grid>
  ) : null;

  return (
    <div>
      <Typography variant="h4" color="primary">
        <FormattedMessage {...messages.title} />
      </Typography>
      <Grid container spacing={4}>
        {checklist}
        {house}
        {recipes}
        {trips}
      </Grid>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  global: makeSelectGlobal(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(HomePage);
