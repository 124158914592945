import { defineMessages } from 'react-intl';

export const scope = 'TripPage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
  },
  description: {
    id: `${scope}.description`,
  },
  new: {
    id: `${scope}.new`,
  },
  create: {
    id: `${scope}.create`,
  },
  name: {
    id: `${scope}.name`,
  },
});
