const itTranslationMessages = require('./translations/it.json');
const enTranslationMessages = require('./translations/en.json');
const esTranslationMessages = require('./translations/es.json');

const DEFAULT_LOCALE = 'it';

const translationMessages = {
  it: itTranslationMessages,
  en: enTranslationMessages,
  es: esTranslationMessages,
};

exports.translationMessages = translationMessages;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
