import { call, put, takeLatest } from 'redux-saga/effects';

import { apiGet } from '@senseisrl/react-template/utils/api';

import { toastErrorAction } from '../Toast/actions';
import { loadErrorAction, loadSuccessAction } from './actions';
import { LOAD } from './constants';

export function* load(action) {
  try {
    const params = { size: 1000 };
    if (action.tag) params.tag = action.tag;
    const response = yield call(apiGet, '/api/v1/recipes', params);
    yield put(loadSuccessAction(response.data.content, response.data.tags));
  } catch (err) {
    yield put(loadErrorAction());
    yield put(toastErrorAction('RecipePage.bad'));
  }
}

export default function* saga() {
  yield takeLatest(LOAD, load);
}
