import { defineMessages } from 'react-intl';

export const scope = 'Header';

export default defineMessages({
  theme: {
    id: `${scope}.theme`,
  },
  language: {
    id: `${scope}.language`,
  },
  logout: {
    id: `${scope}.logout`,
  },
});
