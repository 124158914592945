import React from 'react';

import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
  },
}));

function Spinner() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress size={60} thickness={6} disableShrink />
    </div>
  );
}

export default Spinner;
