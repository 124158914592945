import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectTripDomain = state => state.trip || initialState;

const makeSelectTrip = () =>
  createSelector(selectTripDomain, substate => substate);

export { selectTripDomain, makeSelectTrip };
