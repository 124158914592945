import PropTypes from 'prop-types';
import React, { Children } from 'react';

import { MenuItem, Menu as MuiMenu } from '@material-ui/core';

function Menu(props) {
  return (
    <MuiMenu
      anchorEl={props.anchorEl}
      open={Boolean(props.anchorEl)}
      onClose={() => props.setAnchorEl(null)}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {Children.map(props.children, i => (
        <MenuItem
          onClick={() => {
            if (i.props.onClick) i.props.onClick();
            props.setAnchorEl(null);
          }}
        >
          {i.props.children}
        </MenuItem>
      ))}
    </MuiMenu>
  );
}

Menu.propTypes = {
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func.isRequired,
};

export default Menu;
