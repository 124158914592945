import produce from 'immer';

import { TOAST_IN, TOAST_OUT } from './constants';

export const initialState = {
  queue: [],
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TOAST_IN:
        draft.queue = [
          ...state.queue,
          {
            messageId: action.messageId,
            severity: action.severity ? action.severity : 'error',
          },
        ];
        break;
      case TOAST_OUT:
        if (state.queue) draft.queue = state.queue.slice(1);
        break;
    }
  });

export default reducer;
