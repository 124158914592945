import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  AppBar,
  Avatar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import BrightnessIcon from '@material-ui/icons/Brightness4';
import ExploreIcon from '@material-ui/icons/Explore';
import HouseIcon from '@material-ui/icons/House';
import LanguageIcon from '@material-ui/icons/Language';
import MenuIcon from '@material-ui/icons/Menu';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import { changeLocaleAction } from '@senseisrl/react-template/LanguageProvider/actions';
import { makeSelectLanguage } from '@senseisrl/react-template/LanguageProvider/selectors';
import { apiLogout } from '@senseisrl/react-template/utils/api';

import { gravatar } from '../../utils/gravatar';
import { loginSuccessAction } from '../App/actions';
import messages2 from '../ChecklistPage/messages';
import { isAuthorized } from '../HomePage';
import messages3 from '../HousePage/messages';
import Menu from '../Menu';
import OptionSelect from '../OptionSelect';
import messages4 from '../RecipePage/messages';
import { toggleThemeAction } from '../ThemeProvider/actions';
import messages5 from '../TripPage/messages';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    textDecoration: 'none',
    '& span': {
      color: theme.palette.secondary.main,
    },
  },
  left: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  middle: {
    flex: 1,
    display: 'flex',
    textDecoration: 'none',
    justifyContent: 'center',
    '& span': {
      color: theme.palette.secondary.main,
    },
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    minWidth: 36,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    fontSize: 16,
    fontWeight: 800,
  },
}));

function Header(props) {
  const classes = useStyles();
  const desktop = useMediaQuery('(min-width:600px)');
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const user = props.user ? (
    <Fragment>
      <IconButton
        edge="end"
        color="inherit"
        onClick={ev => setAnchor(ev.currentTarget)}
      >
        <Avatar src={gravatar(props.user.email)} className={classes.small}>
          {props.user.sub.substr(0, 1).toUpperCase()}
        </Avatar>
      </IconButton>
      <Menu anchorEl={anchor} open={Boolean(anchor)} setAnchorEl={setAnchor}>
        <MenuItem onClick={props.onLogout}>
          <FormattedMessage {...messages.logout} />
        </MenuItem>
      </Menu>{' '}
    </Fragment>
  ) : null;
  if (desktop)
    return (
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography
            className={classes.title}
            variant="h6"
            color="inherit"
            component={Link}
            to="/"
          >
            <span>DS</span>Luke
          </Typography>
          <OptionSelect
            onClick={props.onChangeLanguage}
            value={props.language.locale}
            map={(_v, descr) => descr.substr(0, 3)}
          >
            <MenuItem value="it">Italiano</MenuItem>
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="es">Español</MenuItem>
          </OptionSelect>
          <IconButton
            edge={user ? false : 'end'}
            color="inherit"
            onClick={props.onToggleTheme}
          >
            <BrightnessIcon />
          </IconButton>
          {user}
        </Toolbar>
      </AppBar>
    );
  // else mobile
  const navs = [];
  const add = (perm, icon, title, path) => {
    if (isAuthorized(props.permissions, perm)) {
      navs.push(
        <ListItem
          key={path}
          button
          component={Link}
          to={path}
          onClick={() => setOpen(false)}
        >
          <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>,
      );
    }
  };
  add(
    'checklists',
    <PlaylistAddCheckIcon />,
    <FormattedMessage {...messages2.title} />,
    '/checklist',
  );
  add(
    'house',
    <HouseIcon />,
    <FormattedMessage {...messages3.title} />,
    '/house',
  );
  add(
    'recipes',
    <RestaurantIcon />,
    <FormattedMessage {...messages4.title} />,
    '/recipe',
  );
  add(
    'trips',
    <ExploreIcon />,
    <FormattedMessage {...messages5.title} />,
    '/trip',
  );
  if (navs) navs.push(<Divider />);
  return (
    <AppBar position="static" className={classes.mobileAppbar}>
      <Toolbar className={classes.mobileToolbar}>
        <div className={classes.left}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <Typography
          className={classes.middle}
          variant="h6"
          color="inherit"
          component={Link}
          to="/"
        >
          <span>DS</span>Luke
        </Typography>
        <div className={classes.right}>{user}</div>
      </Toolbar>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <List>
          {navs}
          <ListItem key="lang" button>
            <ListItemIcon className={classes.icon}>
              <LanguageIcon />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage {...messages.language} />}
            />
            <OptionSelect
              onClick={props.onChangeLanguage}
              value={props.language.locale}
              map={(_v, descr) => descr.substr(0, 3)}
            >
              <MenuItem value="it">Italiano</MenuItem>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="es">Español</MenuItem>
            </OptionSelect>
          </ListItem>
          <ListItem
            key="theme"
            button
            onClick={() => {
              setOpen(false);
              props.onToggleTheme();
            }}
          >
            <ListItemIcon className={classes.icon}>
              <BrightnessIcon />
            </ListItemIcon>
            <ListItemText primary={<FormattedMessage {...messages.theme} />} />
          </ListItem>
        </List>
      </Drawer>
    </AppBar>
  );
}

Header.propTypes = {
  user: PropTypes.object,
  permissions: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  language: makeSelectLanguage(),
});

function mapDispatchToProps(dispatch) {
  return {
    onToggleTheme: () => dispatch(toggleThemeAction()),
    onChangeLanguage: loc => dispatch(changeLocaleAction(loc)),
    onLogout: () => {
      apiLogout();
      dispatch(loginSuccessAction(null));
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Header);
