import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectRecipeDomain = state => state.recipe || initialState;

const makeSelectRecipe = () =>
  createSelector(selectRecipeDomain, substate => substate);

export { selectRecipeDomain, makeSelectRecipe };
