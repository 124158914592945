import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  Checkbox,
  Divider,
  Fab,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmDialog from '../ConfirmDialog';
import Spinner from '../Spinner';
import {
  addItemAction,
  checkItemAction,
  deleteAction,
  deleteItemsAction,
  loadAction,
} from './actions';
import messages from './messages';
import { makeSelectChecklistDetail } from './selectors';

const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'capitalize',
  },
  floating: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  gap: {
    marginRight: theme.spacing(1),
  },
  bottom: {
    marginBottom: theme.spacing(10),
  },
}));

function list(id, items, change) {
  if (items == null || items.length === 0) return null;
  return (
    <Paper>
      <List>
        {items.map((i, index) => (
          <div>
            {index === 0 ? null : <Divider light />}
            <ListItem key={i.id}>
              <ListItemText primary={i.name} />
              <ListItemSecondaryAction>
                <Checkbox
                  checked={i.done}
                  onChange={ev => change(id, i.id, ev.target.checked)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </div>
        ))}
      </List>
    </Paper>
  );
}

function ChecklistDetailPage(props) {
  useEffect(() => {
    props.onLoad(props.match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [addOpen, setAddOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const classes = useStyles();

  if (props.checklist.name == null) return <Spinner />;

  return (
    <div>
      <Typography variant="h4" color="primary" className={classes.title}>
        {props.checklist.name}
      </Typography>
      <Typography variant="subtitle2" color="textPrimary">
        <FormattedMessage {...messages.createdBy} /> {props.checklist.creator}
      </Typography>
      {list(props.match.params.id, props.checklist.items, props.onCheckItem)}
      <div className={classes.bottom} />
      <div className={classes.floating}>
        <Fab
          color="secondary"
          className={classes.gap}
          onClick={() => setDeleteOpen(true)}
        >
          <DeleteIcon />
        </Fab>
        <Fab color="primary" onClick={() => setAddOpen(true)}>
          <AddIcon />
        </Fab>
      </div>
      <ConfirmDialog
        open={addOpen}
        onClose={() => setAddOpen(false)}
        onOk={() => {
          props.onAdd(props.match.params.id, newName);
          setNewName('');
        }}
        ok={<FormattedMessage {...messages.add} />}
        title={<FormattedMessage {...messages.addTitle} />}
      >
        <TextField
          variant="outlined"
          fullWidth
          label={<FormattedMessage {...messages.name} />}
          value={newName}
          onChange={ev => setNewName(ev.target.value)}
          autoFocus
        />
      </ConfirmDialog>
      <ConfirmDialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onOk={() => props.onDelete(props.match.params.id)}
        ok={<FormattedMessage {...messages.delete} />}
        onOk2={() => props.onDeleteItems(props.match.params.id, 'checked')}
        ok2={<FormattedMessage {...messages.deleteChecked} />}
        onOk3={() => props.onDeleteItems(props.match.params.id, 'last')}
        ok3={<FormattedMessage {...messages.deleteLast} />}
        title={<FormattedMessage {...messages.deleteTitle} />}
      >
        <FormattedMessage {...messages.deleteConfirm} />
      </ConfirmDialog>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  checklist: makeSelectChecklistDetail(),
});

function mapDispatchToProps(dispatch) {
  return {
    onLoad: id => dispatch(loadAction(id)),
    onAdd: (id, text) => dispatch(addItemAction(id, text)),
    onDelete: id => dispatch(deleteAction(id)),
    onDeleteItems: (id, condition) => dispatch(deleteItemsAction(id, condition)),
    onCheckItem: (id, itemId, done) =>
      dispatch(checkItemAction(id, itemId, done)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ChecklistDetailPage);
