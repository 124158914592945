import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import { Router } from 'react-router-dom';

import LanguageProvider from '@senseisrl/react-template/LanguageProvider';
import configureStore from '@senseisrl/react-template/configureStore';
import history from '@senseisrl/react-template/history';
import { apiInit } from '@senseisrl/react-template/utils/api';

import App from './components/App';
import {
  loginFailureAction,
  loginSuccessAction,
} from './components/App/actions';
import ThemeProvider from './components/ThemeProvider';
import { DEFAULT_LOCALE, translationMessages } from './i18n';
import rootReducer from './reducers';
import rootSaga from './sagas';

function ifNotNull(x, callback0, callback1) {
  if (x) callback0(callback1(x));
}

const store = configureStore(
  rootReducer,
  { language: { locale: DEFAULT_LOCALE } },
  rootSaga,
);
ifNotNull(
  apiInit(store, loginFailureAction),
  store.dispatch,
  loginSuccessAction,
);

// do NOT use import { BrowserRouter as Router }, see
// https://stackoverflow.com/questions/42701129/how-to-push-to-history-in-react-router-v4
// it's important to install history@4.x.x in package.json
ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <ThemeProvider>
        <LanguageProvider locale="it" messages={translationMessages}>
          <Router history={history}>
            <App />
          </Router>
        </LanguageProvider>
      </ThemeProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
