import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { Slide, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { toastOutAction } from './actions';
import { makeSelectToast } from './selectors';

function Toast(props) {
  const first = props.toast.queue ? props.toast.queue[0] : null;
  if (first)
    return (
      <Snackbar
        key={props.toast.queue.length}
        open
        autoHideDuration={6000}
        onClose={props.onClose}
        TransitionComponent={props => <Slide {...props} direction="up" />}
      >
        <Alert variant="filled" elevation={6} severity={first.severity}>
          <FormattedMessage id={first.messageId} />
        </Alert>
      </Snackbar>
    );
  return null;
}

const mapStateToProps = createStructuredSelector({
  toast: makeSelectToast(),
});

function mapDispatchToProps(dispatch) {
  return {
    onClose: () => dispatch(toastOutAction()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Toast);
