import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  Fab,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import ConfirmDialog from '../ConfirmDialog';
import Spinner from '../Spinner';
import TripCard from '../TripCard';
import { createAction, loadAction } from './actions';
import messages from './messages';
import { makeSelectTrip } from './selectors';

const useStyles = makeStyles(theme => ({
  floating: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function grid(trips) {
  return (
    <Grid container spacing={4}>
      {trips.map(i => (
        <Grid key={i.id} item xs={12} sm={4}>
          <TripCard
            title={i.name}
            duration={i.duration}
            distance={i.distance}
            to={`/trip/${i.id}`}
          />
        </Grid>
      ))}
    </Grid>
  );
}

function TripPage(props) {
  useEffect(() => {
    props.onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [open, setOpen] = useState(false);
  const [newName, setNewName] = useState('');

  const classes = useStyles();

  const content =
    props.trip.trips == null ? <Spinner /> : grid(props.trip.trips);
  return (
    <div>
      <Typography variant="h4" color="primary">
        <FormattedMessage {...messages.title} />
      </Typography>
      {content}
      <Fab
        className={classes.floating}
        color="primary"
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </Fab>
      <ConfirmDialog
        open={open}
        onClose={() => setOpen(false)}
        title={<FormattedMessage {...messages.new} />}
        ok={<FormattedMessage {...messages.create} />}
        onOk={() => props.onCreate(newName)}
      >
        <TextField
          variant="outlined"
          fullWidth
          label={<FormattedMessage {...messages.name} />}
          value={newName}
          onChange={ev => setNewName(ev.target.value)}
          autoFocus
        />
      </ConfirmDialog>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  trip: makeSelectTrip(),
});

function mapDispatchToProps(dispatch) {
  return {
    onLoad: () => dispatch(loadAction()),
    onCreate: text => dispatch(createAction(text)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TripPage);
